.sign-in-logo-icon {
    max-width: 50px;
}

@media Screen and (min-width: 768px) {
    .sign-in-inputfield {
        max-width: 75%;
        min-width: 75%;
    }
}

#LoginField{
    background-color: var(--background-color);
    margin-top: 10vh;
    margin-bottom: 20vh !important;
    min-width: 30vh;
} 

#loginbutton{
    padding: 12px 12px;
}


