.icon {
    margin-left: 5px !important;
    transition: all 0.15s ease-in-out !important;
}

.icon-active{
    margin-left: 10px !important;
}

.label {
    transition: all 0.15s ease-in-out !important;
}

.label-active {
    color: var(--link-color-hover) !important;
}