:root {
  --bs-body-font-family: "Roboto", sans-serif !important;
  --bs-primary: #0ac798 !important;
}

body {
  font-family: var(--bs-body-font-family) !important;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}