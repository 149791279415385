.card {
    border-radius: 15px !important;
}

.card-header {
    border: none !important
}

@media screen and (min-width: 768px) {
    .card-hover {
        transition: all 0.2s ease-in-out;
        border-color: var(--grey-300);
    }
    
    .card-hover:hover {
        box-shadow: -5px 5px 0px 1px var(--dark-blue__tint), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);;
        transform: translate(5px, -5px);
        border-color: var(--grey-600);
    }
  }