.navbar-logo {
    max-height: 28px;
}

.navbar-academy-text {
    font-size: 19px;
    margin-bottom: -4px;
}

.navbar-academy-text:hover {
    color: var(--col-primary) !important;
}

.navbar-logos{
    transition: all 0.3s ease;
    cursor: pointer;
}

.navbar-logos:hover{
    transform: translateY(-5px) !important;
}