/*****************************************************************************/
/* Spacing */

/* Padding */

.py-6, .pt-6 {
  padding-top: 4.5rem ;
}
  
.py-6, .pb-6 {
  padding-bottom: 4.5rem ;
}
  
.py-7, .pt-7 {
  padding-top: 6rem ;
}
  
.py-7, .pb-7 {
  padding-bottom: 6rem ;
}
  
.py-8, .pt-8 {
  padding-top: 7.5rem ;
}
  
.py-8, .pb-8 {
  padding-bottom: 7.5rem ;
}
  
.py-9, .pt-9 {
  padding-top: 9rem ;
}
  
.py-9, .pb-9 {
  padding-bottom: 9rem ;
}
  
.py-10, .pt-10 {
  padding-top: 13rem ;
}
  
.py-10, .pb-10 {
  padding-bottom: 13rem ;
}

/* Margin */ 

.my-6, .mt-6 {
  padding-top: 4.5rem ;
}
  
.my-6, .mb-6 {
  padding-bottom: 4.5rem ;
}
  
.my-7, .mt-7 {
  padding-top: 6rem ;
}
  
.my-7, .mb-7 {
  padding-bottom: 6rem ;
}
  
.my-8, .mt-8 {
  padding-top: 7.5rem ;
}
  
.my-8, .mb-8 {
  padding-bottom: 7.5rem ;
}
  
.my-9, .mt-9 {
  padding-top: 9rem ;
}
  
.my-9, .mb-9 {
  padding-bottom: 9rem ;
}
  
.my-10, .mt-10 {
  padding-top: 13rem ;
}
  
.my-10, .mb-10 {
  padding-bottom: 13rem ;
}

/*****************************************************************************/
/* Elevation */
/* Derived from Material design */

.elev-none { box-shadow: none;}

.elev-100 {box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);}

.elev-200 {box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);}

.elev-300 {box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);}

.elev-400 {box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);}

.elev-500 {box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20);}

.elev-600 {box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);}

.elev-700 {box-shadow: 0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20);}

.elev-800 {box-shadow: 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20);}

.elev-900 {box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20);}

.elev-1000 {box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20);}


.elev-hover-none:hover { box-shadow: none;}

.elev-hover-100:hover {box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);}

.elev-hover-200:hover {box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);}

.elev-hover-300:hover {box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);}

.elev-hover-400:hover {box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);}

.elev-hover-500:hover {box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20);}

.elev-hover-600:hover {box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);}

.elev-hover-700:hover {box-shadow: 0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20);}

.elev-hover-800:hover {box-shadow: 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20);}

.elev-hover-900:hover {box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20);}

.elev-hover-1000:hover {box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20);}


.elev-inset-100 {box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.14), inset 0 2px 1px -1px rgba(0,0,0,0.12), inset 0 1px 3px 0 rgba(0,0,0,0.20);}

.elev-inset-200 {box-shadow: inset 0 2px 2px 0 rgba(0,0,0,0.14), inset 0 3px 1px -2px rgba(0,0,0,0.12), inset 0 1px 5px 0 rgba(0,0,0,0.20);}

.elev-inset-300 {box-shadow: inset 0 3px 4px 0 rgba(0,0,0,0.14), inset 0 3px 3px -2px rgba(0,0,0,0.12), inset 0 1px 8px 0 rgba(0,0,0,0.20);}

.elev-inset-400 {box-shadow: inset 0 4px 5px 0 rgba(0,0,0,0.14), inset 0 1px 10px 0 rgba(0,0,0,0.12), inset 0 2px 4px -1px rgba(0,0,0,0.20);}

.elev-inset-500 {box-shadow: inset 0 6px 10px 0 rgba(0,0,0,0.14), inset 0 1px 18px 0 rgba(0,0,0,0.12), inset 0 3px 5px -1px rgba(0,0,0,0.20);}

.elev-inset-600 {box-shadow: inset 0 8px 10px 1px rgba(0,0,0,0.14), inset 0 3px 14px 2px rgba(0,0,0,0.12), inset 0 5px 5px -3px rgba(0,0,0,0.20);}

.elev-inset-700 {box-shadow: inset 0 9px 12px 1px rgba(0,0,0,0.14), inset 0 3px 16px 2px rgba(0,0,0,0.12), inset 0 5px 6px -3px rgba(0,0,0,0.20);}

.elev-inset-800 {box-shadow: inset 0 12px 17px 2px rgba(0,0,0,0.14), inset 0 5px 22px 4px rgba(0,0,0,0.12), inset 0 7px 8px -4px rgba(0,0,0,0.20);}

.elev-inset-900 {box-shadow: inset 0 16px 24px 2px rgba(0,0,0,0.14), inset 0 6px 30px 5px rgba(0,0,0,0.12), inset 0 8px 10px -5px rgba(0,0,0,0.20);}

.elev-inset-1000 {box-shadow: inset 0 24px 38px 3px rgba(0,0,0,0.14), inset 0 9px 46px 8px rgba(0,0,0,0.12), inset 0 11px 15px -7px rgba(0,0,0,0.20);}

/*****************************************************************************/
/* TEXT */

/* Font Size */

.fs-7{font-size:0.75rem !important}

.fs-8{font-size:0.5rem !important}

.fs-9{font-size:0.25rem !important}
