.theme-toggle{
    background-color: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-align: inherit;
    text-transform: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}