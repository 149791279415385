.post-container {
  max-width: 70%;
}

@media screen and (max-width: 768px) {
  .post-container {
    max-width: 100%;
  }
}

/* ACADEMY MARKDOWN STYLING */

.academy-md-styles h1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

.academy-md-styles h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

.academy-md-styles h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.academy-md-styles h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

.academy-md-styles h5 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.academy-md-styles h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.academy-md-styles p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.academy-md-styles ul {
    margin-left: 2rem;
}

.academy-md-styles ol {
  margin-left: 2rem;
}

.academy-md-styles li {
    margin-bottom: 0.25rem;
}

.academy-md-styles li p {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.academy-md-styles img {
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
    0 2px 2px rgba(0,0,0,0.12), 
    0 4px 4px rgba(0,0,0,0.12), 
    0 8px 8px rgba(0,0,0,0.12),
    0 16px 16px rgba(0,0,0,0.12);
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 15px;
}

@media Screen and (min-width: 768px) {
    .academy-md-styles img {
        width: 50%;
    }
};

  blockquote {
    border-left: 8px solid #fafafa;
    padding: 1rem;
  }
  
  pre,
  code {
    background-color: var(--col-grey-700) !important;
    padding: 1rem;
  }

  /* TABLES */
  
  .academy-md-styles table {
    border-collapse: collapse;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 2px solid var(--white);
  }
  .academy-md-styles table thead tr th {
    padding: 0.5rem !important;
    border: 2px solid var(--white);
    background-color: var(--grey-100);
  }
  
  .academy-md-styles table tbody tr td {
    padding: 0.5rem !important;    
    border: 2px solid var(--white);
    background-color: var(--grey-100);
  } 