#sp4Hint {
    margin: 10px 0;
  }
  
 #sp4Hint{
    color: #B0B0B0;
    text-align: center;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
  }
  
  #sp4Hint a{
    text-decoration: underline;
    color: #B4A9DB;
  }
  
  #sp4Hint a:hover {
    color: black;
  }

