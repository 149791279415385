#AppFooter {
    padding: 50px 0;
    background-color: var(--dark-blue);
    box-shadow: 0px -3px 15px 0px rgba(0, 0, 0, 0.20);
  }
  
  .footer-logo {
    max-height: 60px;
  }

  .footer__lists {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .footer__lists li {
    list-style: none;
    font-weight: 500;
    margin: 5px 10px;
  }

  .footer__socialIcon {
    width: 35px;
    margin-right: 10px;
  }