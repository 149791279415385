.category-selector-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    align-items: flex-start;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.category-selector-container::-webkit-scrollbar {
    display: none;
}

.category-selector-button {
    border: none;
    color: var(--col-foreground) !important;
    padding-inline: 10px; 
    padding-block: 5px;
    border-radius: 15px;
    margin-right: 10px, !important;
    font-size: 12px;
    flex: 0 0 auto;
}

.cat-selector-selected {
    background-color: var(--col-secondary-tint2);
    color: white !important;
}
