.favorise-button {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    padding: 0;
    margin: 0;
    outline: none;
    text-align: left;
    text-decoration: none;
    color: inherit;
}

.favorise-button:hover {
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .favorise-button-icon {
        transition: all 0.2s ease-in-out;
    }
    
    .favorise-button-icon:hover {
        scale: 1.1;
        transform: translateY(-5px);
    }
  }