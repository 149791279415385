.category-selector-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    align-items: flex-start;

        /* Hide scrollbar for Chrome, Safari and Opera */
        ::-webkit-scrollbar {
            display: none;
        }
    
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
}

.category-selector-button {
    border: none;
    padding-inline: 10px; 
    padding-block: 5px;
    border-radius: 15px;
    margin-inline-end: 5px;
    font-size: 12px;
    flex: 0 0 auto;
}

.selected {
    background-color: var(--col-secondary);
    color: white;
}
