#Hero-Landing {
    min-height: 50vh;
    margin-top: 10vh;
}

#Main-Section{
    box-shadow: 0 -5px 3px -5px rgba(0, 0, 0, 0.1); /* Shadow above the box */
}

#Hero-Landing p{
    margin-bottom: 5vh;
}