:root {
    /* Theming */
    --dark: #212529;
    --light: white;
    --background-color: var(--light);
    --foreground-color: var(--dark);
    --col-navbar-bg: rgba(255, 255, 255, 0.7);

    /* Basic Styleguide */
    --col-primary: #0ac798;
    --col-primary-shade: #069672;
    --col-primary-shade2: ;
    --col-primary-tint: #09E3AC;
    --col-primary-tint2: rgba(92,229,192,1);

    --col-secondary: #232C58;
    --col-secondary-tint: #323f7f;
    --col-secondary-tint2: #495791;

    --dark-blue: #232C58;
    --dark-blue__tint: #323f7f;
    --dark-blue__tint2: rgba(73,87,145,1);
    --dark-blue__tint3: rgb(188, 224, 245);
    --dark-blue__shade: #252a37;

    --col-danger: #dc3545;

    /* Greyscale */
    --white: var(--light);
    --grey-000: #fdfdfd;
    --grey-100: #f0f0f0;
    --grey-200: #aaaaaa;
    --grey-300: #888888;
    --grey-400: #777777;
    --grey-500: #666666;
    --grey-600: #555555;
    --grey-700: #444444;
    --grey-800: #333333;
    --grey-900: #222222;
    --grey-1000: #111111;
    --black: var(--dark);

    /* Fonts */
    --primary-font: 'Roboto', sans-serif;
    --secondary-font: ;

    /* Miscellaneous */
    --link-color: var(--col-primary);
    --link-color-hover: var(--col-primary-tint);
    --link-color-visited: var(--visited);
}

:root.dark-mode {
  --background-color: var(--dark);
  --foreground-color: var(--light);
  --col-navbar-bg: #212529d3;


  /* Greyscale */
  --white: var(--dark);
  --grey-000: #161616;
  --grey-100: #222222;
  --grey-200: #333333;
  --grey-300: #444444;
  --grey-400: #555555;
  --grey-500: #666666;
  --grey-600: #777777;
  --grey-700: #888888;
  --grey-800: #aaaaaa;
  --grey-900: #cccccc;
  --grey-1000: #eeeeee;
  --black: var(--light);

  /* Miscellaneous */
  --link-color: var(--col-primary-tint);
  --link-color-hover: var(--col-primary);
  --link-color-visited: var(white);
}

/* CSS Prefix & Basics*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;

  font-family: var(--primary-font);
}



body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /* Theming */ 
  background: var(--background-color);
  color: var(--foreground-color);
}

img {
  margin: 0;
  max-width: 100%;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-color-hover);
}

a:visited {
  color: var(--link-color-visited);
}

/******************************************************************************/
/* Colors */

/* Primary */ 

.bg-primary {
  background-color: var(--col-primary) !important;
}

.bg-primary-tint {
  background-color: var(--col-primary-tint) !important;
}

.bg-primary-tint2 {
  background-color: var(--col-primary-tint2) !important;
}

.bg-primary-shade {
  background-color: var(--col-primary-shade) !important;
}

.bg-primary-shade2 {
  background-color: var(--col-primary-shade2) !important;
}

/* Secondary */ 

.bg-secondary {
  background-color: var(--col-secondary) !important;
}

.bg-secondary-tint {
  background-color: var(--col-secondary-tint) !important;
}

.bg-secondary-tint2 {
  background-color: var(--col-secondary-tint2) !important;
}

.bg-secondary-shade {
  background-color: var(--col-secondary-shade) !important;
}

.bg-secondary-shade2 {
  background-color: var(--col-secondary-shade2) !important;
}

/* Dark Blue */ 

.bg-darkB{
  background-color: var(--dark-blue) !important;
}

.bg-darkB_tint{
  background-color: var(--dark-blue__tint) !important;
}

/* Greyscale */ 
  
.bg-foreground {
    background-color: var(--foreground-color);
}

.bg-grey-000 {
  background-color: var(--grey-000);
}
  
.bg-grey-100 {
  background-color: var(--grey-100);
}
  
.bg-grey-200 {
  background-color: var(--grey-200);
}
  
.bg-grey-300 {
  background-color: var(--grey-300);
}
  
.bg-grey-400 {
  background-color: var(--grey-400);
}
  
.bg-grey-500 {
  background-color: var(--grey-500);
}
  
.bg-grey-600 {
  background-color: var(--grey-600);
}
  
.bg-grey-700 {
  background-color: var(--grey-700);
}
  
.bg-grey-800 {
  background-color: var(--grey-800);
}
  
.bg-grey-900 {
  background-color: var(--grey-900);
}

.bg-grey-1000 {
  background-color: var(--grey-1000);
}

.bg-background-color {
  background-color: var(--background-color);
}

/* Backgrounds Gradient*/

.backgr_grad {
  background: linear-gradient(60deg, rgba(73,87,145,1) 0%, rgba(58,116,149,1) 45%, rgba(92,229,192,1) 100%);
}

.backgr_grad-2 {
background: linear-gradient(158deg, rgba(73,87,145,1) 0%, rgba(56,119,149,1) 41%, rgba(27,177,156,1) 68%, rgba(11,214,162,1) 80%, rgba(107,232,197,1) 100%); 
}

/* Primary */

.col-primary {
  color: var(--col-primary);
}

.col-primary-tint {
  color: var(--col-primary-tint);
}

.col-primary-tint2 {
  color: var(--col-primary-tint2);
}

.col-primary-shade {
  color: var(--col-primary-shade);
}

.col-primary-shade2 {
  color: var(--col-primary-shade2);
}

/* Secondary */ 

.col-secondary {
  color: var(--dark-blue)
}

/* Greyscale */

.col-white {
  color: var(--foreground-color) !important;
}

.col-grey-000 {
  color: var(--grey-000) !important;
}
  
.col-grey-100 {
  color: var(--grey-100) !important;
}
  
.col-grey-200 {
  color: var(--grey-200) !important;
}
  
.col-grey-300 {
  color: var(--grey-300) !important;
}
  
.col-grey-400 {
  color: var(--grey-400) !important;
}
  
.col-grey-500 {
  color: var(--grey-500) !important;
}
  
.col-grey-600 {
  color: var(--grey-600) !important;
}
  
.col-grey-700 {
  color: var(--grey-700) !important;
}
  
.col-grey-800 {
  color: var(--grey-800) !important;
}
  
.col-grey-900 {
  color: var(--grey-900) !important;
}

.col-grey-1000 {
  color: var(--grey-1000) !important;
}

.col-black {
  color: var(--background-color) !important;
}

.col-blue {
  color: var(--dark-blueblue) !important;
}

.col-definitive-black {
  color: black !important;
}

/*********************************/

/* Button Styles */

.btn {
  text-decoration: none;
  font-weight: 500;
  border-radius: 3px;
  border-width: 2px;
  border-color: var(--col-primary);
  
  transition-duration: .15s !important;
  transition-property: all !important;
}

.btn:hover {
  box-shadow: -5px 5px 0px 1px var(--dark-blue__tint);
  opacity: 0.8;
  text-decoration: none;
  transform: translate(5px, -5px)
}

.btn:disabled{
  background-color: grey !important;
  border-color: grey !important;
}

.btn-primary {
  color: white !important;
  background-color: var(--col-primary) !important;
}

.btn-primary:hover {
  background: var(--col-primary-tint);
  border-color: var(--col-primary-tint);
  color: white !important;
}

.btn-secondary {
  color: var(--col-primary) !important;
  border-color: var(--col-primary) !important;
  background-color: transparent;
}

.btn-secondary:hover {
background-color: var(--col-primary-tint) !important;
border-color: var(--col-primary-tint) !important;
color: var(--white) !important;
}

.btn-white {
  color: var(--light);
  background-color: transparent;
  border-color: white;
}

.btn-white:hover {
  color: var(--light) !important;
}

.btn-danger {
  border-color: var(--col-danger);
  border-width: 2;
}

/******************************************************************************/
/* Navigation */

.nav-link {
  font-weight: 600;
  color: var(--grey-800) !important;
}

.nav-link:hover {
  color: var(--col-primary-tint) !important;
}

.nav-item .active {
  color: var(--col-primary-tint) !important;
}

/********************************************************************************/
/* Miscellaneous Brand Styles */

.accentHeading {
  /* To be used "above" actual/normal heading */
  position: relative;
  font-weight: 700;
  margin-bottom: 7px;
  letter-spacing: 1px;
}

.accentText {
background: -webkit-linear-gradient(117deg, #495791, #6BE8C5);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.accentText-2 {
  background: -webkit-linear-gradient(117deg, #3e4d8b, #42e4b6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }



.backgr_grad {
  background: linear-gradient(60deg, rgba(73,87,145,1) 0%, rgba(58,116,149,1) 45%, rgba(92,229,192,1) 100%);
}